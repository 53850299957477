import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = {
  key: 1,
  class: ""
}
const _hoisted_3 = { class: "px-5 flex justify-between" }
const _hoisted_4 = { class: "flex items-center flex-1" }
const _hoisted_5 = { class: "flex text-xs 2xl:text-sm" }
const _hoisted_6 = { class: "flex justify-between flex-1" }
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = {
  key: 0,
  class: "inputfield inputfield__underline text-sm"
}
const _hoisted_9 = {
  key: 1,
  class: "inputfield inputfield__underline text-sm range-editor"
}
const _hoisted_10 = {
  key: 2,
  class: "inputfield inputfield__underline text-sm range-editor"
}
const _hoisted_11 = {
  key: 0,
  class: ""
}
const _hoisted_12 = { class: "px-5 flex justify-between" }
const _hoisted_13 = { class: "flex items-center flex-1" }
const _hoisted_14 = { class: "flex text-xs 2xl:text-sm" }
const _hoisted_15 = { class: "flex justify-between flex-1" }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = {
  key: 0,
  class: "inputfield inputfield__underline text-sm"
}
const _hoisted_18 = {
  key: 1,
  class: "inputfield inputfield__underline text-sm"
}
const _hoisted_19 = {
  key: 0,
  class: ""
}
const _hoisted_20 = { class: "px-5 flex justify-between" }
const _hoisted_21 = { class: "flex items-center flex-1" }
const _hoisted_22 = { class: "flex text-xs 2xl:text-sm" }
const _hoisted_23 = { class: "flex justify-between flex-1" }
const _hoisted_24 = { class: "flex items-center" }
const _hoisted_25 = {
  key: 0,
  class: "inputfield inputfield__underline text-sm"
}
const _hoisted_26 = {
  key: 1,
  class: "inputfield inputfield__underline text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, {
    class: "mt-2",
    modelValue: $setup.EnergyMonitoring,
    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($setup.EnergyMonitoring) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, {
        label: "Performance Overview",
        name: "1"
      }, {
        default: _withCtx(() => [
          ($setup.PerformanceOverviewChart === $setup.TAB_TIME['1D'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode($setup["AreaChart"], {
                  style: { height: '360px' },
                  data: $setup.dataPerformanceOverview.dataExpectedPVEnergy,
                  colors: "#22cbca",
                  data_2: $setup.dataPerformanceOverview.dataPVEnergy,
                  colors_2: "#b62202",
                  seriesName: "Expc. PV Energy",
                  seriesName_2: "PV Energy",
                  lineSeriesName: "PV Energy Perf. Ratio",
                  lineData: $setup.dataPerformanceOverview.dataPVEnergyPerformanceRatio,
                  legendStyle: {
            position: 'right',
            fontSize: 14,
            padding: [0, 30, 30, 30],
          },
                  legendLayout: "vertical",
                  withExtraYScale: true,
                  xTicks: "8",
                  yTicks: "200"
                }, null, 8, ["data", "data_2", "lineData"])
              ]))
            : _createCommentVNode("", true),
          ($setup.PerformanceOverviewChart === $setup.TAB_TIME['7D'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (!$setup.loadingPerformanceOverview)
                  ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                      key: 0,
                      style: { height: '360px' },
                      data: $setup.dataPerformanceOverview7Day.dataPVEnergyPerformanceRatio7Day,
                      colors: ['#22c8ca', '#22c8ca', '#22c8ca'],
                      data_2: $setup.dataPerformanceOverview7Day.dataExpectedPVEnergy7Day,
                      colors_2: ['#b62202', '#b62202', '#b62202'],
                      seriesName: "Expc. PV Energy",
                      seriesName_2: "PV Energy",
                      withExtraYScale: true,
                      lineMarker: $setup.dataPerformanceOverview7Day.dataPVEnergy7Day,
                      lineMarkerName: "PV Energy Perf. Ratio",
                      lineMarkerColor: "#178b7f",
                      yTitle: "Tonnes CO2",
                      legendStyle: {
            position: 'right',
            fontSize: 13,
            padding: [0, 30, 100, 50],
          },
                      legendLayout: "vertical"
                    }, null, 8, ["data", "data_2", "lineMarker"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400 rounded-tl-lg rounded-bl-lg',
                $setup.PerformanceOverviewChart === $setup.TAB_TIME['1D'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['1D'])), ["prevent"]))
                }, " 1D ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400',
                $setup.PerformanceOverviewChart === $setup.TAB_TIME['7D'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['7D'])), ["prevent"]))
                }, " 7D ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400',
                $setup.PerformanceOverviewChart === $setup.TAB_TIME['1M'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['1M'])), ["prevent"]))
                }, " 1M ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400',
                $setup.PerformanceOverviewChart === $setup.TAB_TIME['MTD'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['MTD'])), ["prevent"]))
                }, " MTD ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400 rounded-tr-lg rounded-br-lg',
                $setup.PerformanceOverviewChart === $setup.TAB_TIME['YTD'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['YTD'])), ["prevent"]))
                }, " YTD ", 2)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                ($setup.PerformanceOverviewChart === $setup.TAB_TIME['1D'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_el_date_picker, {
                        modelValue: $setup.OneDatePerformanceOverview,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.OneDatePerformanceOverview) = $event)),
                        type: "date",
                        editable: false,
                        clearable: false,
                        "disabled-date": (time) => time.getTime() > Date.now(),
                        placeholder: "",
                        onChange: $setup.handleChangeOneDatePerformanceOverview
                      }, null, 8, ["modelValue", "disabled-date"])
                    ]))
                  : _createCommentVNode("", true),
                ($setup.PerformanceOverviewChart === $setup.TAB_TIME['7D'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_el_date_picker, {
                        modelValue: $setup.OneWeekPerformanceOverview,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.OneWeekPerformanceOverview) = $event)),
                        type: "daterange",
                        editable: false,
                        clearable: false,
                        "range-separator": "To",
                        "start-placeholder": "Start date",
                        "end-placeholder": "End date",
                        "disabled-date": 
                  (time) => {
                    if (time.getTime() > $setup.defaultWeekEndDate) return true;
                  }
                ,
                        onChange: $setup.handleChangeOneWeekPerformanceOverview
                      }, null, 8, ["modelValue", "disabled-date"])
                    ]))
                  : _createCommentVNode("", true),
                ($setup.PerformanceOverviewChart === $setup.TAB_TIME['1M'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_el_date_picker, {
                        class: "daterange",
                        modelValue: $setup.OneMonthPerformanceOverview,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.OneMonthPerformanceOverview) = $event)),
                        type: "daterange",
                        editable: false,
                        clearable: false,
                        "range-separator": "To",
                        "start-placeholder": "Start date",
                        "end-placeholder": "End date",
                        "disabled-date": 
                   (time) => {
                    if (time.getTime() > $setup.defaultMonthEndDate) return true;
                  }
                ,
                        onChange: $setup.handleChangeOneMonthPerformanceOverview
                      }, null, 8, ["modelValue", "disabled-date"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "flex items-center" }, [
              _createElementVNode("button", { class: "btn py-1 border-2 border-white hover:bg-gray-700 active:bg-gray-800" }, " Download CSV ")
            ], -1))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, {
        label: "Load Performance",
        name: "2"
      }, {
        default: _withCtx(() => [
          ($setup.LoadPerformanceChart === $setup.TAB_TIME['1D'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode($setup["AreaChart"], {
                  style: { height: '360px' },
                  data: $setup.dataLoadPerformance,
                  colors: "#e07151",
                  seriesName: "Active Power",
                  legendStyle: {
            position: 'top',
            fontSize: 14,
          },
                  xTicks: "120"
                }, null, 8, ["data"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400 rounded-tl-lg rounded-bl-lg',
                $setup.LoadPerformanceChart === $setup.TAB_TIME['1D'] && _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['1D'])), ["prevent"]))
                }, " 1D ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400',
                $setup.LoadPerformanceChart === $setup.TAB_TIME['7D'] && _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['7D'])), ["prevent"]))
                }, " 7D ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400',
                $setup.LoadPerformanceChart === $setup.TAB_TIME['1M'] && _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['1M'])), ["prevent"]))
                }, " 1M ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400',
                $setup.LoadPerformanceChart === $setup.TAB_TIME['MTD'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['MTD'])), ["prevent"]))
                }, " MTD ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400 rounded-tr-lg rounded-br-lg',
                $setup.LoadPerformanceChart === $setup.TAB_TIME['YTD'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['YTD'])), ["prevent"]))
                }, " YTD ", 2)
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                ($setup.LoadPerformanceChart === $setup.TAB_TIME['1D'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createVNode(_component_el_date_picker, {
                        modelValue: $setup.OneDatePerformanceOverview,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.OneDatePerformanceOverview) = $event)),
                        type: "date",
                        editable: false,
                        clearable: false,
                        "disabled-date": (time) => time.getTime() > Date.now(),
                        placeholder: "",
                        onChange: $setup.handleChangeOneDatePerformanceOverview
                      }, null, 8, ["modelValue", "disabled-date"])
                    ]))
                  : _createCommentVNode("", true),
                ($setup.LoadPerformanceChart === $setup.TAB_TIME['7D'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createVNode(_component_el_date_picker, {
                        class: "daterange",
                        modelValue: $setup.OneWeekPerformanceOverview,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.OneWeekPerformanceOverview) = $event)),
                        type: "daterange",
                        editable: false,
                        clearable: false,
                        "range-separator": "To",
                        "start-placeholder": "Start date",
                        "end-placeholder": "End date",
                        onChange: $setup.handleChangeOneWeekPerformanceOverview
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "flex items-center" }, [
                _createElementVNode("button", { class: "btn py-1 border-2 border-white hover:bg-gray-700 active:bg-gray-800" }, " Download CSV ")
              ], -1))
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, {
        label: "System Performance",
        name: "3"
      }, {
        default: _withCtx(() => [
          ($setup.SystemPerformanceChart === $setup.TAB_TIME['1D'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createVNode($setup["AreaChart"], {
                  style: { height: '360px' },
                  data: $setup.dataSystemPerformance.dataBatterySystemPerformance,
                  colors: "#e07151",
                  seriesName: "Battery",
                  data_2: $setup.dataSystemPerformance.gridSystemPerformance,
                  colors_2: "#4181ee",
                  seriesName_2: "Grid",
                  data_3: $setup.dataSystemPerformance.loadSystemPerformance,
                  colors_3: "#18e2e1",
                  seriesName_3: "Load",
                  data_4: $setup.dataSystemPerformance.pvSystemPerformance,
                  colors_4: "#a3d19d",
                  seriesName_4: "PV",
                  legendStyle: {
            position: 'right',
            fontSize: 14,
          },
                  legendLayout: "vertical",
                  xTicks: "120"
                }, null, 8, ["data", "data_2", "data_3", "data_4"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400 rounded-tl-lg rounded-bl-lg',
                $setup.SystemPerformanceChart === $setup.TAB_TIME['1D'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[15] || (_cache[15] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['1D'])), ["prevent"]))
                }, " 1D ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400',
                $setup.SystemPerformanceChart === $setup.TAB_TIME['7D'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['7D'])), ["prevent"]))
                }, " 7D ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400',
                $setup.SystemPerformanceChart === $setup.TAB_TIME['1M'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[17] || (_cache[17] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['1M'])), ["prevent"]))
                }, " 1M ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400',
                $setup.SystemPerformanceChart === $setup.TAB_TIME['MTD'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['MTD'])), ["prevent"]))
                }, " MTD ", 2),
                _createElementVNode("button", {
                  class: _normalizeClass([
                'py-1 px-2 border border-gray-400 rounded-tr-lg rounded-br-lg',
                $setup.SystemPerformanceChart === $setup.TAB_TIME['YTD'] &&
                  _ctx.styles['tab-active'],
              ]),
                  onClick: _cache[19] || (_cache[19] = _withModifiers(($event: any) => ($setup.handlePerformanceChangeTime($setup.TAB_TIME['YTD'])), ["prevent"]))
                }, " YTD ", 2)
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                ($setup.SystemPerformanceChart === $setup.TAB_TIME['1D'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createVNode(_component_el_date_picker, {
                        modelValue: $setup.OneDatePerformanceOverview,
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.OneDatePerformanceOverview) = $event)),
                        type: "date",
                        editable: false,
                        clearable: false,
                        "disabled-date": (time) => time.getTime() > Date.now(),
                        placeholder: "",
                        onChange: $setup.handleChangeOneDatePerformanceOverview
                      }, null, 8, ["modelValue", "disabled-date"])
                    ]))
                  : _createCommentVNode("", true),
                ($setup.SystemPerformanceChart === $setup.TAB_TIME['7D'])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      _createVNode(_component_el_date_picker, {
                        class: "daterange",
                        modelValue: $setup.OneWeekPerformanceOverview,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($setup.OneWeekPerformanceOverview) = $event)),
                        type: "daterange",
                        editable: false,
                        clearable: false,
                        "range-separator": "To",
                        "start-placeholder": "Start date",
                        "end-placeholder": "End date",
                        onChange: $setup.handleChangeOneWeekPerformanceOverview
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[25] || (_cache[25] = _createElementVNode("div", { class: "flex items-center" }, [
                _createElementVNode("button", { class: "btn py-1 border-2 border-white hover:bg-gray-700 active:bg-gray-800" }, " Download CSV ")
              ], -1))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}