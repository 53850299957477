import { defineComponent as _defineComponent } from 'vue'
import { ref, toRefs } from "vue";
import {
  IOverviewGeneration,
  IOverviewConsumption,
  IOverviewEnergyStorage,
} from "@/models/Site";
interface Props {
  dataOverviewGeneration: IOverviewGeneration;
  dataOverviewConsumption: IOverviewConsumption;
  dataOverviewEnergyStorage: IOverviewEnergyStorage;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TheOverview',
  props: {
    dataOverviewGeneration: {},
    dataOverviewConsumption: {},
    dataOverviewEnergyStorage: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { dataOverviewGeneration } = toRefs(props);

const Overview = ref("1");
const customColor = ref("#addfa4");

const __returned__ = { props, dataOverviewGeneration, Overview, customColor, ref, toRefs, get IOverviewGeneration() { return IOverviewGeneration }, get IOverviewConsumption() { return IOverviewConsumption }, get IOverviewEnergyStorage() { return IOverviewEnergyStorage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})