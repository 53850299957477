import { defineComponent as _defineComponent } from 'vue'
import { ref, toRefs } from "vue";
import ColumnChart from "@/components/AnyChart/ColumnChart.vue";

interface Props {
  dataCarbonEmissionByScope1: any[];
  dataCarbonEmissionByScope3: any[];
  dataCarbonTargetByScope1: any[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TheCarbonEmissionsByScope',
  props: {
    dataCarbonEmissionByScope1: {},
    dataCarbonEmissionByScope3: {},
    dataCarbonTargetByScope1: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const CarbonEmissionsByScope = ref("1");

const props = __props;
const {
  dataCarbonEmissionByScope1,
  dataCarbonEmissionByScope3,
  dataCarbonTargetByScope1,
} = toRefs(props);

const __returned__ = { CarbonEmissionsByScope, props, dataCarbonEmissionByScope1, dataCarbonEmissionByScope3, dataCarbonTargetByScope1, ref, toRefs, ColumnChart }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})