import { defineComponent as _defineComponent } from 'vue'
import { Field, Form } from "vee-validate";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AlarmQuery.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();

const onSubmit = async (values: any) => {
  router.push("/");
};

const __returned__ = { router, onSubmit, get Field() { return Field }, get Form() { return Form }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})