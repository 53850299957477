import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, toRefs } from "vue";
import AreaChart from "@/components/AnyChart/AreaChart.vue";
import ColumnChart from "@/components/AnyChart/ColumnChart.vue";
import { KEYS_QUERY } from "@ems/constants";
import EnergyMonitoringModule from "@ems/containers/FacilitiesMonitoring/EnergyMonitoring.module";
import { formatLocalDate } from "@/utils/helpers/convertDateTime";
interface Props {
  dataPerformanceOverview: any;
  dataPerformanceOverview7Day: any;
  dataPerformanceOverview1Month: any;
  dataPerformanceOverviewMTD: any;
  dataPerformanceOverviewYTD: any;
  dataLoadPerformance: any;
  dataLoadPerformance7Day: any;
  dataSystemPerformance: any;
  dataSystemPerformance7Day: any;
}

enum TAB_TIME {
  "1D" = "1D",
  "7D" = "7D",
  "1M" = "1M",
  "MTD" = "MTD",
  "YTD" = "YTD",
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TheEnergyMonitoring',
  props: {
    dataPerformanceOverview: {},
    dataPerformanceOverview7Day: {},
    dataPerformanceOverview1Month: {},
    dataPerformanceOverviewMTD: {},
    dataPerformanceOverviewYTD: {},
    dataLoadPerformance: {},
    dataLoadPerformance7Day: {},
    dataSystemPerformance: {},
    dataSystemPerformance7Day: {}
  },
  emits: ["handleChangeTabOverviewEnergy", "handleChangeOneDatePicker", "handleChangeOneWeekPicker", "handleChangeOneMonthPicker"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;

const props = __props;
const {
  dataPerformanceOverview,
  dataPerformanceOverview7Day,
  dataPerformanceOverview1Month,
  dataPerformanceOverviewMTD,
  dataPerformanceOverviewYTD,
  dataLoadPerformance,
  dataLoadPerformance7Day,
  dataSystemPerformance,
  dataSystemPerformance7Day,
} = toRefs(props);

const loadingPerformanceOverview = computed(
  () => EnergyMonitoringModule.loadingPerformanceOverviewGetter
);
const EnergyMonitoring = ref("1");
const PerformanceOverviewChart = ref(TAB_TIME["1D"]);
const LoadPerformanceChart = ref(TAB_TIME["1D"]);
const SystemPerformanceChart = ref(TAB_TIME["1D"]);

const OneDatePerformanceOverview = ref(new Date());
const OneWeekPerformanceOverview = ref("");
const OneMonthPerformanceOverview = ref("");
const MTDPerformanceOverview = ref("");
const YTDPerformanceOverview = ref("");

const defaultWeekEndDate = computed(() => {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  return date;
});

const defaultMonthEndDate = computed(() => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return date;
});

const handleChangeOneDatePerformanceOverview = (value: Date | Event) => {
  const datePicker = value as Date;
  OneDatePerformanceOverview.value = datePicker;

  emits("handleChangeOneDatePicker", {
    tab: KEYS_QUERY.DAY,
    date: formatLocalDate(datePicker),
  });
};

const handleChangeOneWeekPerformanceOverview = (value: any) => {
  const startDate = value[0];
  const endDate = value[1];

  emits("handleChangeOneWeekPicker", {
    tab: KEYS_QUERY.SEVEN_DAY,
    startDate: formatLocalDate(startDate),
    endDate: formatLocalDate(endDate),
  });
};

const handleChangeOneMonthPerformanceOverview = (value: any) => {
  const startDate = value[0];
  const endDate = value[1];

  emits("handleChangeOneMonthPicker", {
    tab: KEYS_QUERY.MONTH,
    startDate: formatLocalDate(startDate),
    endDate: formatLocalDate(endDate),
  });
};

const handlePerformanceChangeTime = (withTab: TAB_TIME) => {
  if (EnergyMonitoring.value === "1") {
    PerformanceOverviewChart.value = withTab;
    switch (withTab) {
      case TAB_TIME["1D"]:
        emits("handleChangeTabOverviewEnergy", KEYS_QUERY.DAY);
        break;
      case TAB_TIME["7D"]:
        emits("handleChangeTabOverviewEnergy", KEYS_QUERY.SEVEN_DAY);
        break;
      case TAB_TIME["1M"]:
        emits("handleChangeTabOverviewEnergy", KEYS_QUERY.MONTH);
        break;
      case TAB_TIME["MTD"]:
        emits("handleChangeTabOverviewEnergy", KEYS_QUERY.MONTH_TO_DATE);
        break;
      case TAB_TIME["YTD"]:
        emits("handleChangeTabOverviewEnergy", KEYS_QUERY.YEAR_TO_DATE);
        break;
      default:
        emits("handleChangeTabOverviewEnergy", KEYS_QUERY.DAY);
        break;
    }
  }
  if (EnergyMonitoring.value === "2") {
    LoadPerformanceChart.value = withTab;
  }
  if (EnergyMonitoring.value === "3") {
    SystemPerformanceChart.value = withTab;
  }
};

const __returned__ = { emits, props, dataPerformanceOverview, dataPerformanceOverview7Day, dataPerformanceOverview1Month, dataPerformanceOverviewMTD, dataPerformanceOverviewYTD, dataLoadPerformance, dataLoadPerformance7Day, dataSystemPerformance, dataSystemPerformance7Day, loadingPerformanceOverview, TAB_TIME, EnergyMonitoring, PerformanceOverviewChart, LoadPerformanceChart, SystemPerformanceChart, OneDatePerformanceOverview, OneWeekPerformanceOverview, OneMonthPerformanceOverview, MTDPerformanceOverview, YTDPerformanceOverview, defaultWeekEndDate, defaultMonthEndDate, handleChangeOneDatePerformanceOverview, handleChangeOneWeekPerformanceOverview, handleChangeOneMonthPerformanceOverview, handlePerformanceChangeTime, computed, ref, toRefs, AreaChart, ColumnChart, get KEYS_QUERY() { return KEYS_QUERY }, get EnergyMonitoringModule() { return EnergyMonitoringModule }, get formatLocalDate() { return formatLocalDate } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})