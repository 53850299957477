import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full h-full 2xl:py-5 py-3" }
const _hoisted_2 = { class: "w-96 2xl:w-full h-full text-sm text-center" }
const _hoisted_3 = { class: "border-b border-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "text-left text-sm font-semibold" }, "Solar PV Output Forecast", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("th", null, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data, (dataForest) => {
              return (_openBlock(), _createElementBlock("th", {
                class: "font-normal",
                key: dataForest.Day
              }, _toDisplayString($setup.parseDateData(dataForest.Day, dataForest.Month)), 1))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", null, [
            _cache[1] || (_cache[1] = _createElementVNode("td", null, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data, (dataForest) => {
              return (_openBlock(), _createElementBlock("td", {
                key: dataForest.Day,
                class: _normalizeClass([_ctx.styles['text-cold']])
              }, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "w-5 h-5",
                  name: $setup.formatIconWeather(dataForest.WeatherCondition)
                }, null, 8, ["name"])
              ], 2))
            }), 128))
          ]),
          _createElementVNode("tr", null, [
            _cache[2] || (_cache[2] = _createElementVNode("td", { class: "text-left" }, "Hi:", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data, (dataForest) => {
              return (_openBlock(), _createElementBlock("td", {
                key: dataForest.Day,
                class: _normalizeClass([_ctx.styles['text-heat']])
              }, _toDisplayString(dataForest.TemperatureHi) + "℃ ", 3))
            }), 128))
          ]),
          _createElementVNode("tr", null, [
            _cache[3] || (_cache[3] = _createElementVNode("td", { class: "text-left" }, "Lo:", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data, (dataForest) => {
              return (_openBlock(), _createElementBlock("td", {
                key: dataForest.Day,
                class: _normalizeClass([_ctx.styles['text-green']])
              }, _toDisplayString(dataForest.TemperatureLow) + "℃ ", 3))
            }), 128))
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("tr", null, [
            _createElementVNode("td", {
              colspan: "4",
              class: "text-left"
            }, "Pv")
          ], -1)),
          _createElementVNode("tr", null, [
            _cache[4] || (_cache[4] = _createElementVNode("td", { class: "text-left" }, "Forecast:", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data, (dataForest) => {
              return (_openBlock(), _createElementBlock("td", {
                key: dataForest.Day,
                class: _normalizeClass([_ctx.styles['text-warm']])
              }, _toDisplayString(dataForest.PVForecast) + "℃ ", 3))
            }), 128))
          ])
        ])
      ])
    ])
  ], 64))
}