import { defineComponent as _defineComponent } from 'vue'
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { formatDayOfWeek } from "@/utils/helpers/convertDateTime";
import { formatIconWeather } from "@/utils/helpers/convertWeather";
import { toRefs } from "vue";
interface Props {
  data: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TheWeatherForecast',
  props: {
    data: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { data } = toRefs(props);
const parseDateData = (day: string, month: string): string => {
  if (day && month) {
    const dateData = `${new Date().getFullYear()}-${month}-${day}`;
    return `${formatDayOfWeek(dateData)} \n ${day}/${month}`;
  }
  return "";
};

const __returned__ = { props, data, parseDateData, BaseSvgIcon, get formatDayOfWeek() { return formatDayOfWeek }, get formatIconWeather() { return formatIconWeather }, toRefs }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})