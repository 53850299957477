import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-wrap w-full 2xl:space-x-3" }
const _hoisted_2 = { class: "box flex-2 bg-cafe-dark relative" }
const _hoisted_3 = { class: "box h-52 2xl:flex-1 bg-cafe-dark relative mt-3 2xl:mt-0 mr-3 2xl:mr-0" }
const _hoisted_4 = { class: "box h-52 flex-1 bg-cafe-dark relative mt-3 2xl:mt-0" }
const _hoisted_5 = { class: "space-y-1" }
const _hoisted_6 = { class: "flex items-center space-x-3" }
const _hoisted_7 = { class: "flex items-center space-x-3" }
const _hoisted_8 = { class: "flex items-center space-x-3" }
const _hoisted_9 = { class: "flex items-center space-x-3" }
const _hoisted_10 = { class: "box mt-3 relative p-0" }
const _hoisted_11 = { class: "flex items-center space-x-3" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode($setup["AlarmQueryForm"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "text-left text-sm font-semibold" }, "Facility Status", -1)),
        _createVNode($setup["DoughnutChart"], {
          class: "h-48",
          data: $setup.piedStatus,
          legendStyle: {
          position: 'right',
          fontSize: 12,
        }
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "text-left text-sm font-semibold" }, "Realtime Alarm", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", {
                class: _normalizeClass(['w-3 h-3 rounded-full', _ctx.styles['bg-red']])
              }, null, 2),
              _cache[1] || (_cache[1] = _createElementVNode("p", null, [
                _createTextVNode("Critical ("),
                _createElementVNode("span", null, "0"),
                _createTextVNode(")")
              ], -1))
            ]),
            _createVNode(_component_el_progress, {
              class: "progress-custom",
              "show-text": false,
              "stroke-width": 10,
              percentage: 0,
              color: $setup.customColorCritical
            }, null, 8, ["color"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", {
                class: _normalizeClass(['w-3 h-3 rounded-full', _ctx.styles['bg-brown']])
              }, null, 2),
              _cache[2] || (_cache[2] = _createElementVNode("p", null, [
                _createTextVNode("Major ("),
                _createElementVNode("span", null, "0"),
                _createTextVNode(")")
              ], -1))
            ]),
            _createVNode(_component_el_progress, {
              class: "progress-custom",
              "show-text": false,
              "stroke-width": 10,
              percentage: 0,
              color: $setup.customColorMajor
            }, null, 8, ["color"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", {
                class: _normalizeClass(['w-3 h-3 rounded-full', _ctx.styles['bg-orange']])
              }, null, 2),
              _cache[3] || (_cache[3] = _createElementVNode("p", null, [
                _createTextVNode("Minor ("),
                _createElementVNode("span", null, "0"),
                _createTextVNode(")")
              ], -1))
            ]),
            _createVNode(_component_el_progress, {
              class: "progress-custom",
              "show-text": false,
              "stroke-width": 10,
              percentage: 0,
              color: $setup.customColorMinor
            }, null, 8, ["color"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", {
                class: _normalizeClass(['w-3 h-3 rounded-full', _ctx.styles['bg-yellow']])
              }, null, 2),
              _cache[4] || (_cache[4] = _createElementVNode("p", null, [
                _createTextVNode("Warning ("),
                _createElementVNode("span", null, "0"),
                _createTextVNode(")")
              ], -1))
            ]),
            _createVNode(_component_el_progress, {
              class: "progress-custom",
              "show-text": false,
              "stroke-width": 10,
              percentage: 0,
              color: $setup.customColorWarning
            }, null, 8, ["color"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_el_table, {
        class: "w-full bg-cafe-dark table-custom",
        style: { borderRadius: '20px' },
        data: $setup.dataTable,
        border: "",
        stripe: "",
        "max-height": "320",
        "cell-class-name": "text-white bg-cafe-dark",
        "header-row-class-name": "tableHeaderRow",
        "row-class-name": "tableRow"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "Id",
            label: "No.",
            width: "80"
          }),
          _createVNode(_component_el_table_column, {
            prop: "Severity",
            label: "Severity"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_11, [
                (scope.row.Severity === $setup.SEVERITY.CRITICAL)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      class: _normalizeClass(['w-3 h-3 rounded-full', _ctx.styles['bg-red']])
                    }, null, 2))
                  : _createCommentVNode("", true),
                (scope.row.Severity === $setup.SEVERITY.MAJOR)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      class: _normalizeClass(['w-3 h-3 rounded-full', _ctx.styles['bg-brown']])
                    }, null, 2))
                  : _createCommentVNode("", true),
                (scope.row.Severity === $setup.SEVERITY.MINOR)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 2,
                      class: _normalizeClass(['w-3 h-3 rounded-full', _ctx.styles['bg-orange']])
                    }, null, 2))
                  : _createCommentVNode("", true),
                (scope.row.Severity === $setup.SEVERITY.WARNING)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 3,
                      class: _normalizeClass(['w-3 h-3 rounded-full', _ctx.styles['bg-yellow']])
                    }, null, 2))
                  : _createCommentVNode("", true),
                _createElementVNode("p", null, _toDisplayString(scope.row.Severity), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "Category",
            label: "Category"
          }),
          _createVNode(_component_el_table_column, {
            prop: "Device",
            label: "Device"
          }),
          _createVNode(_component_el_table_column, {
            prop: "AlarmDescription",
            label: "Alarm Description",
            width: "180"
          }),
          _createVNode(_component_el_table_column, {
            prop: "AlarmStartTime",
            label: "Alarm Start Time",
            width: "150"
          }),
          _createVNode(_component_el_table_column, {
            prop: "AlarmStopTime",
            label: "Alarm Stop Time",
            width: "150"
          }),
          _createVNode(_component_el_table_column, {
            prop: "Status",
            label: "Status",
            width: "100"
          }),
          _createVNode(_component_el_table_column, {
            label: "Action",
            width: "80"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("button", {
                type: "button",
                onClick: ($event: any) => ($setup.handleEdit(scope.$index, scope.row))
              }, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-white hover:text-blue-400 w-5 h-5",
                  name: "edit"
                })
              ], 8, _hoisted_12)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ])
  ], 64))
}