import { defineComponent as _defineComponent } from 'vue'
import { onMounted, onUnmounted, ref, toRefs } from "vue";
import { IFacilitySummaryInfo } from "@/models/Site";
interface Props {
  dataFacilitySummaryInfo: IFacilitySummaryInfo;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TheSystemOperationalDiagram',
  props: {
    dataFacilitySummaryInfo: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { dataFacilitySummaryInfo } = toRefs(props);
//import BaseSvgImage from "@/components/BaseSvgImage.vue";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const LeaderLine = require("leader-line-vue").default;

const box1 = ref(null);
const box2 = ref(null);
const box3 = ref(null);
const box4 = ref(null);
const box5 = ref(null);
const box6 = ref(null);

const fakeBox4 = ref(null);

const line1 = ref(null) as any;
const line2 = ref(null) as any;
const line3 = ref(null) as any;
const line4 = ref(null) as any;
const line5 = ref(null) as any;

onMounted(() => {
  line1.value = LeaderLine.setLine(box1.value, fakeBox4.value, {
    size: 4,
    color: "#676767",
    path: "grid",
    endPlug: "behind",
    dash: { animation: true },
  });

  line2.value = LeaderLine.setLine(box2.value, box4.value, {
    size: 4,
    color: "#676767",
    path: "straight",
    endPlug: "behind",
    dash: { animation: true },
  });

  line3.value = LeaderLine.setLine(box3.value, box4.value, {
    size: 4,
    color: "#676767",
    path: "grid",
    endPlug: "behind",
    dash: { animation: true },
  });

  line4.value = LeaderLine.setLine(box4.value, box5.value, {
    size: 4,
    color: "#676767",
    path: "grid",
    endPlug: "behind",
    dash: { animation: true },
  });

  line5.value = LeaderLine.setLine(box5.value, box6.value, {
    size: 4,
    color: "#676767",
    path: "grid",
    endPlug: "behind",
    dash: { animation: true },
  });
});

onUnmounted(() => {
  line1.value.remove();
  line2.value.remove();
  line3.value.remove();
  line4.value.remove();
  line5.value.remove();
});

const __returned__ = { props, dataFacilitySummaryInfo, LeaderLine, box1, box2, box3, box4, box5, box6, fakeBox4, line1, line2, line3, line4, line5, onMounted, onUnmounted, ref, toRefs, get IFacilitySummaryInfo() { return IFacilitySummaryInfo } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})