import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold mb-2" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "w-40 h-full absolute left-0 top-0" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "overflow-x-auto ml-40 pb-2" }
const _hoisted_6 = {
  class: "w-52 h-32 bg-cafe relative text-sm",
  style: {
                      padding: '15px',
                      borderRadius: '20px',
                      border: 'solid 1px #5e5e5e',
                    }
}
const _hoisted_7 = { class: "flex justify-between" }
const _hoisted_8 = { class: "flex justify-between" }
const _hoisted_9 = { class: "flex justify-between" }
const _hoisted_10 = { class: "flex justify-between" }
const _hoisted_11 = { class: "flex justify-between" }
const _hoisted_12 = {
  class: "h-32 w-full bg-cafe relative",
  style: {
          padding: '10px 25px',
          borderRadius: '20px',
          border: 'solid 1px #5e5e5e',
        }
}
const _hoisted_13 = { class: "h-full flex justify-around items-center" }
const _hoisted_14 = { class: "space-y-3 text-center" }
const _hoisted_15 = { class: "space-y-3 text-center" }
const _hoisted_16 = { class: "space-y-3 text-center" }
const _hoisted_17 = { class: "space-y-3 text-center" }
const _hoisted_18 = { class: "text-xl font-semibold mb-5" }
const _hoisted_19 = {
  class: "ml-1",
  style: { color: '#18e2e1' }
}
const _hoisted_20 = { class: "relative" }
const _hoisted_21 = { class: "w-40 h-full absolute left-0 top-0" }
const _hoisted_22 = { class: "flex flex-col" }
const _hoisted_23 = { class: "overflow-x-auto ml-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, {
    class: "mt-2",
    modelValue: $setup.Overview,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.Overview) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, { name: "1" }, {
        label: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("h1", { class: "text-sm" }, "Generation", -1)
        ])),
        default: _withCtx(() => [
          _createElementVNode("h1", _hoisted_1, [
            _cache[2] || (_cache[2] = _createTextVNode(" Solar ")),
            _createElementVNode("span", {
              class: _normalizeClass(['ml-1', _ctx.styles['text-orange']])
            }, _toDisplayString($setup.dataOverviewGeneration.SolarPower) + " kW", 3)
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("hr", { class: "mb-5 border-gray-400" }, null, -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", {
                  class: _normalizeClass([_ctx.styles['table-th']])
                }, "Name", 2),
                _createElementVNode("p", {
                  class: _normalizeClass([_ctx.styles['table-th']])
                }, "Rated Power (kW)", 2),
                _createElementVNode("p", {
                  class: _normalizeClass([_ctx.styles['table-th']])
                }, "Power (kW)", 2),
                _createElementVNode("p", {
                  class: _normalizeClass([_ctx.styles['table-th']])
                }, "Power / Capacity", 2),
                _createElementVNode("p", {
                  class: _normalizeClass([_ctx.styles['table-th']])
                }, "Status (4)", 2),
                _createElementVNode("p", {
                  class: _normalizeClass([_ctx.styles['table-th']])
                }, null, 2)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("table", {
                class: _normalizeClass([_ctx.styles['table']])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataOverviewGeneration.InverterData, (item, index) => {
                  return (_openBlock(), _createElementBlock("tbody", { key: index }, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", null, _toDisplayString(item.Name), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", null, _toDisplayString(item.RatedPower), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", null, _toDisplayString(item.Power), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", null, [
                        _createVNode(_component_el_progress, {
                          class: "progress-custom",
                          style: { marginTop: '3px', marginBottom: '3px' },
                          "text-inside": true,
                          "stroke-width": 16,
                          percentage: item.PowerCapacityPercentage,
                          color: $setup.customColor
                        }, null, 8, ["percentage", "color"])
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", null, [
                        _createElementVNode("span", {
                          class: _normalizeClass(_ctx.styles['text-green'])
                        }, _toDisplayString(item.Status), 3)
                      ])
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", null, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-gray-400" }, "Active Power", -1)),
                            _createElementVNode("span", {
                              class: _normalizeClass(['font-semibold', _ctx.styles['text-orange']])
                            }, _toDisplayString(item.Details["Active Power"]) + "kW ", 3)
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-gray-400" }, "DC Current 1", -1)),
                            _createElementVNode("span", {
                              class: _normalizeClass(['font-semibold', _ctx.styles['text-orange']])
                            }, _toDisplayString(item.Details["DC Current 1"]) + "A ", 3)
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-gray-400" }, "DC Current 2", -1)),
                            _createElementVNode("span", {
                              class: _normalizeClass(['font-semibold', _ctx.styles['text-orange']])
                            }, _toDisplayString(item.Details["DC Current 2"]) + "A ", 3)
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-gray-400" }, "DC Voltage 1", -1)),
                            _createElementVNode("span", {
                              class: _normalizeClass(['font-semibold', _ctx.styles['text-orange']])
                            }, _toDisplayString(item.Details["DC Voltage 1"]) + "v ", 3)
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-gray-400" }, "DC Voltage 2", -1)),
                            _createElementVNode("span", {
                              class: _normalizeClass(['font-semibold', _ctx.styles['text-orange']])
                            }, _toDisplayString(item.Details["DC Voltage 2"]) + "v ", 3)
                          ])
                        ])
                      ])
                    ])
                  ]))
                }), 128))
              ], 2)
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { name: "2" }, {
        label: _withCtx(() => _cache[9] || (_cache[9] = [
          _createElementVNode("h1", { class: "text-sm" }, "Consumption", -1)
        ])),
        default: _withCtx(() => [
          _cache[14] || (_cache[14] = _createElementVNode("h1", { class: "text-xl font-semibold mb-5" }, "Overall Consumption", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _cache[10] || (_cache[10] = _createElementVNode("p", null, "Overall", -1)),
                _createElementVNode("p", {
                  class: _normalizeClass(['text-xl font-semibold', _ctx.styles['text-gold']])
                }, _toDisplayString($props.dataOverviewConsumption.Overall) + " kWh ", 3)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[11] || (_cache[11] = _createElementVNode("p", null, "Active Power", -1)),
                _createElementVNode("p", {
                  class: _normalizeClass(['text-xl font-semibold', _ctx.styles['text-gold']])
                }, _toDisplayString($props.dataOverviewConsumption.ActivePower) + " kW ", 3)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[12] || (_cache[12] = _createElementVNode("p", null, "Reactive Power", -1)),
                _createElementVNode("p", {
                  class: _normalizeClass(['text-xl font-semibold', _ctx.styles['text-gold']])
                }, _toDisplayString($props.dataOverviewConsumption.ReactivePower) + " VAR ", 3)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _cache[13] || (_cache[13] = _createElementVNode("p", null, "Apparent Power", -1)),
                _createElementVNode("p", {
                  class: _normalizeClass(['text-xl font-semibold', _ctx.styles['text-gold']])
                }, _toDisplayString($props.dataOverviewConsumption.ApparentPower) + " VA ", 3)
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { name: "3" }, {
        label: _withCtx(() => _cache[15] || (_cache[15] = [
          _createElementVNode("h1", { class: "text-sm" }, "Energy Storage", -1)
        ])),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("h1", _hoisted_18, [
              _cache[16] || (_cache[16] = _createTextVNode(" Battery ")),
              _createElementVNode("span", _hoisted_19, _toDisplayString($props.dataOverviewEnergyStorage.BatteryPower) + " kW ", 1)
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("p", {
                    class: _normalizeClass([_ctx.styles['table-th']])
                  }, "Name", 2),
                  _createElementVNode("p", {
                    class: _normalizeClass([_ctx.styles['table-th']])
                  }, "Rated Power (kW)", 2),
                  _createElementVNode("p", {
                    class: _normalizeClass([_ctx.styles['table-th']])
                  }, "Power (kW)", 2),
                  _createElementVNode("p", {
                    class: _normalizeClass([_ctx.styles['table-th']])
                  }, "Power / Capacity", 2),
                  _createElementVNode("p", {
                    class: _normalizeClass([_ctx.styles['table-th']])
                  }, "SOC", 2),
                  _createElementVNode("p", {
                    class: _normalizeClass([_ctx.styles['table-th']])
                  }, "Status (4)", 2)
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("table", {
                  class: _normalizeClass([_ctx.styles['table']])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dataOverviewEnergyStorage.BatteryData, (item, index) => {
                    return (_openBlock(), _createElementBlock("tbody", { key: index }, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(item.Name), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(item.RatedPower), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(item.Power), 1)
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", null, [
                          _createVNode(_component_el_progress, {
                            class: "progress-custom",
                            style: { marginTop: '3px', marginBottom: '3px' },
                            "text-inside": true,
                            "stroke-width": 16,
                            percentage: item.PowerCapacityPercentage,
                            color: $setup.customColor
                          }, null, 8, ["percentage", "color"])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", null, [
                          _createVNode(_component_el_progress, {
                            class: "progress-custom",
                            style: { marginTop: '3px', marginBottom: '3px' },
                            "text-inside": true,
                            "stroke-width": 16,
                            percentage: item.SOC,
                            color: $setup.customColor
                          }, null, 8, ["percentage", "color"])
                        ])
                      ]),
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", null, [
                          _createElementVNode("span", {
                            class: _normalizeClass(_ctx.styles['text-green'])
                          }, _toDisplayString(item.Status), 3)
                        ])
                      ])
                    ]))
                  }), 128))
                ], 2)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}