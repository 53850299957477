import { defineComponent as _defineComponent } from 'vue'
import { toRefs } from "vue";
import ColumnChart from "@/components/AnyChart/ColumnChart.vue";
interface Props {
  dataScopeTwoEnergyLoad: any;
  dataScopeTwoRenewableEnergy: any;
  dataScopeTwoLineMarker: any;
  dataScopeTwoEmissionTarget: any;
  dataMaxPercentChart: any;
  isLoadingScopeTwoEnergyLoad: any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TheRenewableEnergyPerformance',
  props: {
    dataScopeTwoEnergyLoad: {},
    dataScopeTwoRenewableEnergy: {},
    dataScopeTwoLineMarker: {},
    dataScopeTwoEmissionTarget: {},
    dataMaxPercentChart: {},
    isLoadingScopeTwoEnergyLoad: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const {
  dataScopeTwoEnergyLoad,
  dataScopeTwoRenewableEnergy,
  dataScopeTwoLineMarker,
  dataScopeTwoEmissionTarget,
  dataMaxPercentChart,
  isLoadingScopeTwoEnergyLoad,
} = toRefs(props);

const __returned__ = { props, dataScopeTwoEnergyLoad, dataScopeTwoRenewableEnergy, dataScopeTwoLineMarker, dataScopeTwoEmissionTarget, dataMaxPercentChart, isLoadingScopeTwoEnergyLoad, toRefs, ColumnChart }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})