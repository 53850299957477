import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between w-full h-full pl-16 pr-8 pt-4 pb-8" }
const _hoisted_2 = { class: "grid grid-cols-5" }
const _hoisted_3 = {
  class: "flex justify-center items-center w-10 h-8 relative",
  ref: "box1"
}
const _hoisted_4 = { class: "text-center text-sm absolute -left-14" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  class: "flex justify-center items-center w-14 relative",
  ref: "box2"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "text-center text-sm absolute -right-16" }
const _hoisted_9 = { class: "grid grid-cols-5" }
const _hoisted_10 = {
  class: "flex justify-center items-center w-12 relative",
  ref: "box3"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "flex w-28 space-x-1 text-center text-sm absolute -bottom-6" }
const _hoisted_13 = {
  class: "flex justify-center items-center w-14 h-12 relative",
  ref: "box4"
}
const _hoisted_14 = {
  class: "w-14 h-8 absolute top-0 left-0",
  ref: "fakeBox4"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "flex justify-center items-center w-full relative" }
const _hoisted_17 = {
  class: "flex justify-center items-center w-12",
  ref: "box5"
}
const _hoisted_18 = { class: "text-center text-sm absolute -top-8" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "flex text-sm space-x-1 absolute -bottom-6" }
const _hoisted_21 = { class: "flex justify-end items-center w-full relative" }
const _hoisted_22 = {
  class: "flex justify-center items-center w-10 h-10",
  ref: "box6"
}
const _hoisted_23 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text-gray-400" }, "Battery", -1)),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.styles['text-green'], 'whitespace-nowrap'])
          }, _toDisplayString($setup.dataFacilitySummaryInfo.BatteryLoad) + " kWh ", 3)
        ]),
        _createElementVNode("img", {
          class: "pt-1 pr-1 h-8 w-15",
          src: require('@/assets/images/systemdiagram/Battery.png'),
          alt: "battery"
        }, null, 8, _hoisted_5)
      ], 512),
      _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          class: "pt-1 pr-1 h-12 w-15",
          src: require('@/assets/images/systemdiagram/Load.png'),
          alt: "load"
        }, null, 8, _hoisted_7),
        _createElementVNode("div", _hoisted_8, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-gray-400" }, "Load", -1)),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.styles['text-green'], 'whitespace-nowrap'])
          }, _toDisplayString($setup.dataFacilitySummaryInfo.LoadConsumption) + " kWh ", 3)
        ])
      ], 512),
      _cache[3] || (_cache[3] = _createElementVNode("div", null, null, -1)),
      _cache[4] || (_cache[4] = _createElementVNode("div", null, null, -1))
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("img", {
          src: require('@/assets/images/systemdiagram/Solar.png'),
          alt: "Solar PV"
        }, null, 8, _hoisted_11),
        _createElementVNode("div", _hoisted_12, [
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-gray-400" }, "Solar PV", -1)),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.styles['text-green'], 'whitespace-nowrap'])
          }, _toDisplayString($setup.dataFacilitySummaryInfo.SolarPVGeneration) + " kWh ", 3)
        ])
      ], 512),
      _cache[10] || (_cache[10] = _createElementVNode("div", null, null, -1)),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, null, 512),
        _createElementVNode("img", {
          class: "pt-1 pr-1 h-10 w-16",
          src: require('@/assets/images/systemdiagram/Controller.png'),
          alt: "controller"
        }, null, 8, _hoisted_15),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-sm absolute -bottom-6" }, [
          _createElementVNode("p", { class: "text-gray-400" }, "Controller")
        ], -1))
      ], 512),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-gray-400" }, "Import", -1)),
            _createElementVNode("p", {
              class: _normalizeClass([_ctx.styles['text-green'], 'whitespace-nowrap'])
            }, _toDisplayString($setup.dataFacilitySummaryInfo.ImportFromGrid) + " kWh ", 3)
          ]),
          _createElementVNode("img", {
            class: "",
            src: require('@/assets/images/systemdiagram/Export.png'),
            alt: "export"
          }, null, 8, _hoisted_19),
          _createElementVNode("div", _hoisted_20, [
            _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-gray-400" }, "Export", -1)),
            _createElementVNode("p", {
              class: _normalizeClass([_ctx.styles['text-green'], 'whitespace-nowrap'])
            }, _toDisplayString($setup.dataFacilitySummaryInfo.ExportFromGrid) + " kWh ", 3)
          ])
        ], 512)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("img", {
            class: "",
            src: require('@/assets/images/systemdiagram/Grid.png'),
            alt: "grid"
          }, null, 8, _hoisted_23),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-sm absolute -bottom-6" }, [
            _createElementVNode("p", { class: "text-gray-400" }, "Grid")
          ], -1))
        ], 512)
      ])
    ])
  ]))
}