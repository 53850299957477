import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "h-full flex justify-around items-center" }
const _hoisted_2 = { class: "space-y-3 mr-3" }
const _hoisted_3 = { class: "2xl:text-lg font-semibold" }
const _hoisted_4 = { class: "space-y-3" }
const _hoisted_5 = { class: "2xl:text-lg font-semibold" }
const _hoisted_6 = { class: "space-y-3 mr-3" }
const _hoisted_7 = { class: "2xl:text-lg font-semibold" }
const _hoisted_8 = { class: "space-y-3 mr-3" }
const _hoisted_9 = { class: "2xl:text-lg font-semibold" }
const _hoisted_10 = { class: "space-y-3 mr-3" }
const _hoisted_11 = { class: "2xl:text-lg font-semibold" }
const _hoisted_12 = { class: "space-y-3" }
const _hoisted_13 = { class: "2xl:text-lg font-semibold" }
const _hoisted_14 = {
  key: 0,
  class: "m-0 w-16 transform -rotate-90"
}
const _hoisted_15 = {
  key: 1,
  class: "space-y-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.styles['text-gold'])
      }, "Irradiance", 2),
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.dataFacilitySummaryInfo.Irradiance) + "W/m ", 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.styles['text-gold'])
      }, "Temperature", 2),
      _createElementVNode("p", _hoisted_5, _toDisplayString($setup.dataFacilitySummaryInfo.Temperature) + " ℃ ", 1)
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "m-0 w-16 transform -rotate-90" }, [
      _createElementVNode("hr", { class: "border-gray-400 m-0" })
    ], -1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.styles['text-gold'])
      }, "Solar PV Generation", 2),
      _createElementVNode("p", _hoisted_7, _toDisplayString($setup.dataFacilitySummaryInfo.SolarPVGeneration) + "kWh ", 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.styles['text-gold'])
      }, "Load Consumption", 2),
      _createElementVNode("p", _hoisted_9, _toDisplayString($setup.dataFacilitySummaryInfo.LoadConsumption) + "kWh ", 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.styles['text-gold'])
      }, "Import from Grid", 2),
      _createElementVNode("p", _hoisted_11, _toDisplayString($setup.dataFacilitySummaryInfo.ImportFromGrid) + "kWh ", 1)
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("p", {
        class: _normalizeClass(_ctx.styles['text-gold'])
      }, "Battery level", 2),
      _createElementVNode("p", _hoisted_13, _toDisplayString($setup.dataFacilitySummaryInfo.BatteryLevel) + "% ", 1)
    ]),
    (!$setup.noPlantStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[0] || (_cache[0] = [
          _createElementVNode("hr", { class: "border-gray-400 m-0" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (!$setup.noPlantStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.styles['text-gold'])
          }, "Plant Status", 2),
          _createElementVNode("p", {
            class: _normalizeClass(['2xl:text-lg font-semibold', _ctx.styles['text-green']])
          }, _toDisplayString($setup.dataFacilitySummaryInfo.PlantStatus == 0 ? "Normal" : ""), 3)
        ]))
      : _createCommentVNode("", true)
  ]))
}