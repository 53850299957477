import { defineComponent as _defineComponent } from 'vue'
import { IGreenHouseGasesAvoided } from "@/models/Site";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import { toRefs } from "vue";

interface Props {
  dataGreenHouseGases: IGreenHouseGasesAvoided;
  horizontal?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TheGreenHouseGasesAvoided',
  props: {
    dataGreenHouseGases: {},
    horizontal: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { dataGreenHouseGases, horizontal } = toRefs(props);

const formatCO2e = (data: number) => {
  return data && data.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const __returned__ = { props, dataGreenHouseGases, horizontal, formatCO2e, get IGreenHouseGasesAvoided() { return IGreenHouseGasesAvoided }, BaseSvgIcon, toRefs }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})