import { defineComponent as _defineComponent } from 'vue'
import { toRefs } from "vue";
import { IFacilitySummaryInfo } from "@/models/Site";
interface Props {
  dataFacilitySummaryInfo: IFacilitySummaryInfo;
  noPlantStatus?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TheStatsBar',
  props: {
    dataFacilitySummaryInfo: {},
    noPlantStatus: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { dataFacilitySummaryInfo, noPlantStatus } = toRefs(props);

const __returned__ = { props, dataFacilitySummaryInfo, noPlantStatus, toRefs, get IFacilitySummaryInfo() { return IFacilitySummaryInfo } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})