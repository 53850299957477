import { defineComponent as _defineComponent } from 'vue'
import { ref, toRefs } from "vue";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";

import DoughnutChart from "@/components/AnyChart/DoughnutChart.vue";
import AlarmQueryForm from "./TheForm/AlarmQuery.Form.vue";

import { SEVERITY } from "@ems/constants";

interface Props {
  dataTable: any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TheOperationsMaintenanceAlarms',
  props: {
    dataTable: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const piedStatus = [
  { x: "Offline", value: 0, fill: "#a5a5a5" },
  { x: "Normal", value: 4, fill: "#528235" },
  { x: "Faulty", value: 1, fill: "#843c0b" },
];

const customColorCritical = ref("#e31b0b");
const customColorMajor = ref("#a04d1c");
const customColorMinor = ref("#e07151");
const customColorWarning = ref("#edf609");
const props = __props;
const { dataTable } = toRefs(props);

const handleEdit = (idx: number, item: any) => {
  // DialogModule.showModal({
  //   component: UpdateAccountForm,
  //   data: { idx, ...item },
  // });
};
const handleDelete = (idx: number, item: any) => {
  // DialogModule.showModal({
  //   component: DeleteAccount,
  //   data: { idx, ...item },
  // });
};

const __returned__ = { piedStatus, customColorCritical, customColorMajor, customColorMinor, customColorWarning, props, dataTable, handleEdit, handleDelete, ref, toRefs, BaseSvgIcon, DoughnutChart, AlarmQueryForm, get SEVERITY() { return SEVERITY } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})