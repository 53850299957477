import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mr-5 h-30 border border-gray-500 border-opacity-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([$setup.horizontal && 'flex items-center space-x-5'])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("h1", {
        class: _normalizeClass([
          'text-left text-sm font-semibold uppercase opacity-50 whitespace-nowrap',
          _ctx.styles['text-green'],
        ])
      }, " greenhouse gases avoided ", 2),
      _createElementVNode("p", {
        class: _normalizeClass([
          _ctx.styles['rectangle'],
          'p-3',
          $setup.horizontal
            ? 'flex flex-col justify-center h-30'
            : 'flex justify-between items-center',
        ])
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['2xl:text-2xl', $setup.horizontal && 'px-2 py-3'])
        }, _cache[0] || (_cache[0] = [
          _createTextVNode("CO"),
          _createElementVNode("sub", null, "2", -1),
          _createTextVNode("e:")
        ]), 2),
        _createElementVNode("span", {
          class: _normalizeClass([
            '2xl:text-2xl',
            _ctx.styles['text-green'],
            $setup.horizontal && 'self-end px-2 py-3',
          ])
        }, _toDisplayString($setup.formatCO2e($setup.dataGreenHouseGases.CO2e)) + " kg", 3)
      ], 2)
    ]),
    ($setup.horizontal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("h1", {
        class: _normalizeClass([
          'text-left text-sm font-semibold uppercase opacity-50 whitespace-nowrap',
          _ctx.styles['text-green'],
        ])
      }, " environmental benefits ", 2),
      _createElementVNode("div", {
        class: _normalizeClass([$setup.horizontal ? 'flex space-x-2' : 'space-y-2'])
      }, [
        _createElementVNode("p", {
          class: _normalizeClass([
            _ctx.styles['rectangle'],
            $setup.horizontal
              ? 'flex flex-col h-30 p-5'
              : 'flex items-center 2xl:h-20 p-3',
          ])
        }, [
          _createVNode($setup["BaseSvgIcon"], {
            class: _normalizeClass([
              $setup.horizontal
                ? 'w-8 h-8 2xl:w-16 2xl:h-16'
                : 'w-10 h-10 2xl:w-20 2xl:h-20',
            ]),
            name: "car"
          }, null, 8, ["class"]),
          _createElementVNode("span", {
            class: _normalizeClass([!$setup.horizontal && 'ml-3', 'text-sm'])
          }, [
            _cache[1] || (_cache[1] = _createTextVNode(" Equivalent to the CO2 emitted by ")),
            _createElementVNode("span", {
              class: _normalizeClass([_ctx.styles['text-green']])
            }, _toDisplayString($setup.dataGreenHouseGases.PassengerCarEquivalent), 3),
            _cache[2] || (_cache[2] = _createTextVNode(" passenger cars emit over 1 year "))
          ], 2)
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass([
            _ctx.styles['rectangle'],
            $setup.horizontal
              ? 'flex flex-col h-30 p-5'
              : 'flex items-center 2xl:h-20 p-3',
          ])
        }, [
          _createVNode($setup["BaseSvgIcon"], {
            class: "w-8 h-8 2xl:w-16 2xl:h-16",
            name: "tree"
          }),
          _createElementVNode("span", {
            class: _normalizeClass([!$setup.horizontal && 'ml-3', 'text-sm'])
          }, [
            _cache[3] || (_cache[3] = _createTextVNode(" Equivalent to the CO2 absorbed by ")),
            _createElementVNode("span", {
              class: _normalizeClass([_ctx.styles['text-green']])
            }, _toDisplayString($setup.dataGreenHouseGases.TreesEquivalent), 3),
            _cache[4] || (_cache[4] = _createTextVNode(" trees over a year. "))
          ], 2)
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass([
            _ctx.styles['rectangle'],
            $setup.horizontal
              ? 'flex flex-col h-30 p-5'
              : 'flex items-center 2xl:h-20 p-3',
          ])
        }, [
          _createVNode($setup["BaseSvgIcon"], {
            class: "w-8 h-8 2xl:w-16 2xl:h-16",
            name: "house"
          }),
          _createElementVNode("span", {
            class: _normalizeClass([!$setup.horizontal && 'ml-3', 'text-sm'])
          }, [
            _cache[5] || (_cache[5] = _createTextVNode(" Equivalent to the CO2 emitted by ")),
            _createElementVNode("span", {
              class: _normalizeClass([_ctx.styles['text-green']])
            }, _toDisplayString($setup.dataGreenHouseGases.HomesEquivalent), 3),
            _cache[6] || (_cache[6] = _createTextVNode(" homes over a year. "))
          ], 2)
        ], 2)
      ], 2)
    ])
  ], 2))
}