import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl font-bold mb-10" }, " Scope 2 Renewables Performance ", -1)),
    (!$setup.isLoadingScopeTwoEnergyLoad)
      ? (_openBlock(), _createBlock($setup["ColumnChart"], {
          key: 0,
          class: "h-96 relative",
          data: $setup.dataScopeTwoEnergyLoad,
          colors: ['#ffeecc', '#ffeecc', '#5e5e5e'],
          data_2: $setup.dataScopeTwoRenewableEnergy,
          colors_2: ['#addfa4', '#addfa4', '#5e5e5e'],
          seriesName: "Energy Consumption (kWh)",
          seriesName_2: "Renewables (kWh)",
          lineSeriesName: "Renewables (%)",
          lineData: $setup.dataScopeTwoLineMarker,
          lineMarker: $setup.dataScopeTwoEmissionTarget,
          percentChart: $setup.dataMaxPercentChart,
          lineMarkerName: "Target (%)",
          yTitle: "kWh",
          legendStyle: {
      position: 'bottom',
      fontSize: 12,
      padding: [15, 0, 0, 0],
    },
          withExtraYScale: true,
          lineAllowExtraYScale: true
        }, null, 8, ["data", "data_2", "lineData", "lineMarker", "percentChart"]))
      : _createCommentVNode("", true)
  ], 64))
}